<template>
	<PoliPusat v-if="cabang.jenis == 'pusat'" />
	<PoliCabang v-else />
</template>

<script setup>
import store from '@/store'
import { computed } from 'vue-demi';

import PoliPusat from './component/poli/PoliPusat.vue'
import PoliCabang from './component/poli/PoliCabang.vue'

const cabang = computed(() => store.getters['auth/cabang']);
</script>
