<template>
	<div>
	<ContentHeader title="Data Poli" />
     <div class="content-header">
		<div class="container-fluid">
			<div class="row justify-content-center">
	            <div class="col">
	            	<div class="card">
	            		<div class="card-header row d-flex align-items-center">
	            			<h3 class="card-title col-6">Data Poli Cabang</h3>
	            			<div class="col-6 text-right">
	            				<router-link to="/tambah-poli"><button class="btn btn-sm btn-info">Tambah</button></router-link>	
	            			</div>
	            		</div>
	            		<div class="card-body">
	            			<div class="row align-items-center">
	            				<div class="col-lg-2">
	            					<span>Jumlah Baris</span>
	            					<select class="custom-select form-control" v-model="page" @change="getDataResult">
		            					<option value="10">10</option>
		            					<option value="15">15</option>
		            					<option value="20">20</option>
		            					<option value="25">25</option>
		            					<option value="30">30</option>
		            					<option value="40">40</option>
		            					<option value="45">45</option>
		            					<option value="50">50</option>
		            				</select>
	            				</div>
	            				<div class="col-lg-2">
	            				</div>
	            				<div class="col-lg-8"></div>
	            			</div>
	            			<table class="table table-bordered table-hover mt-4">
							  <thead>
							    <tr>
							      <th scope="col" class="text-sm" style="min-width: 200px;">NAMA</th>
							      <th scope="col" class="text-sm text-center" style="min-width: 80px;">STATUS</th>
							      <th scope="col" class="text-sm text-center" style="min-width: 80px;">ACTION</th>
							    </tr>
							  </thead>
							  <tbody>
							    <tr v-for="row in data_result" :key="row.id">
							      <td class="text-sm" scope="row" style="min-width: 200px;">{{ row.poli.nama }}</td>
							      <td class="text-sm" style="min-width: 80px;" align="center">
  									<el-tag class="ml-2" type="success" v-if="row.status == 1">Aktif</el-tag>
  									<el-tag class="ml-2" type="info" v-else>Tidak Aktif</el-tag>
							  	  </td>
								  <td class="text-sm" style="min-width: 80px;" align="center">
							      	<div class="btn-group">
										<button type="button" class="btn btn-sm btn-info text-sm">Proses</button>
										<button type="button" class="btn btn-sm btn-info dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false">
										<span class="sr-only"></span>
										</button>
										<div class="dropdown-menu" role="menu" style="">
										<router-link :to="`/edit-poli/${row.id}`" class="dropdown-item text-sm">Edit</router-link>
										</div>
									</div>
							      </td>
							    </tr>
							  </tbody>
							</table>
							<p class="text-center mt-4" v-if="memuat_data">Memuat Data</p>
							<p class="text-center mt-4" v-if="cek_data">Data Masih Kosong</p>
	            		</div>
	            		<div class="card-footer">
							<nav aria-label="...">
							  <ul class="pagination">
							    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.from}" @click="paginateData(pagination.prev_page_url)">
							      <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
							    </li>
							    <li class="page-item" :class="{ active: pagination.current_page == row.label }" @click="paginateData(row.url)" v-for="(row, index) in links" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
							    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.last_page}" @click="paginateData(pagination.next_page_url)">
							      <a class="page-link" href="#">Next</a>
							    </li>
							  </ul>
							</nav>
	            		</div>
	            	</div>
	            </div>
	        </div>
		</div>
	</div>
    </div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader'

import axios from 'axios'
import { ref, onMounted, computed } from 'vue' 
import store from '@/store'

const cabang = computed(() => store.getters['auth/cabang'])
const data_result = ref([])
const cek_data = ref(false)
const memuat_data = ref(true)
const page = ref(10)
const links = ref([])
const pagination = ref([])

const getDataResult = async () => {
	data_result.value = []
	memuat_data.value = true

	let { data } = await axios.get(`api/poli/cabang/${cabang.value.id}/${page.value}`)

	if (data != 'kosong') {
		memuat_data.value = false
		cek_data.value = false
		data_result.value = data.data
		links.value = data.links
		links.value.splice(0, 1)
		links.value.splice(links.value.length-1, 1)
		pagination.value = data
	} else {
		cek_data.value = true
		memuat_data.value = false
		data_result.value = []
	}
}

const paginateData = async (url) => {

	if (url != null) {
		data_result.value = []
		memuat_data.value = true

		let link_url = new URL(url)
		link_url = `${link_url.pathname}${link_url.search}`
		
		let { data } = await axios.get(`${link_url}`)

		
		if (data != 'kosong') {
			memuat_data.value = false
			data_result.value = data.data	
			links.value = data.links
			links.value.splice(0, 1)
			links.value.splice(links.value.length-1, 1)
			pagination.value = data
		}
	}
}

onMounted(() => {
	getDataResult()
})
</script>

<style>
	
</style>